import AuthService from '../../../services/AuthService'
import axios from 'axios'
import jwtDefaultConfig from './jwtDefaultConfig'

export default class JwtService {
    //HTTP client
    httpClient

    // ** jwtConfig <= Will be used by this service
    jwtConfig = { ...jwtDefaultConfig }

    // ** For Refreshing Token
    isAlreadyFetchingAccessToken = false

    // ** For Refreshing Token
    subscribers = []

    constructor(baseUrlApi, jwtOverrideConfig) {
        this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }
        // httpClient configurations
        this.httpClient = axios.create({
            baseURL: baseUrlApi,
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-type': 'application/json',
                'Access-Control-Allow-Credentials': 'true'
            },
            //timeout: 1000 * 120, // Wait for 2 minutes
            //timeout: 1000 * 300, // Wait for 5 minutes
            timeout: 1000 * 600, // Wait for 10 minutes
            timeoutErrorMessage: 'Time out, api did not respond'
        })

        // ** Request Interceptor
        this.httpClient.interceptors.request.use(
            config => {
                // ** Get token from localStorage
                const accessToken = this.getToken()

                // ** If token is present add it to request's Authorization Header
                if (accessToken) {
                    config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
                }
                config.headers['X-EmpresaId'] = AuthService.getCurrentEmpresaId() || -1
                return config
            },
            error => Promise.reject(error)
        )

        // ** Add request/response interceptor
        this.httpClient.interceptors.response.use(
            response => response,
            error => {
                // ** const { config, response: { status } } = error
                const { config, response } = error
                const originalRequest = config

                // ** if (status === 401) {
                if (response && response.status === 401) {
                    if (!this.isAlreadyFetchingAccessToken) {
                        this.isAlreadyFetchingAccessToken = true
                        AuthService.signinSilent()
                            .then(userData => {
                                this.isAlreadyFetchingAccessToken = false
                                // ** Update accessToken in localStorage
                                //this.setToken(r.data.accessToken)
                                //this.setRefreshToken(r.data.refreshToken)
                                this.onAccessTokenFetched(userData.access_token)
                            })
                            .catch(error => {
                                console.error(
                                    `jwtService->this.httpClient.interceptors.response: ${JSON.stringify(
                                        error
                                    )}`
                                )
                                this.isAlreadyFetchingAccessToken = false
                            })
                    }
                    const retryOriginalRequest = new Promise(resolve => {
                        this.addSubscriber(() => {
                            // ** Make sure to assign accessToken according to your response.
                            // ** Check: https://pixinvent.ticksy.com/ticket/2413870
                            // ** Change Authorization header
                            const localAccessToken = this.getToken()
                            originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${localAccessToken}`
                            resolve(this.axios(originalRequest))
                        })
                    })
                    return retryOriginalRequest
                }
                return Promise.reject(error)
            }
        )
    }

    getHttpClient() {
        return this.httpClient
    }

    onAccessTokenFetched(accessToken) {
        this.subscribers = this.subscribers.filter(callback => callback(accessToken))
    }

    addSubscriber(callback) {
        this.subscribers.push(callback)
    }

    getToken() {
        return AuthService.getToken()
        //return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
    }

    getRefreshToken() {
        return AuthService.getToken()
        //return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
    }

    //setToken(value) {
    //    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
    //}

    //setRefreshToken(value) {
    //    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
    //}

    //login(...args) {
    //    return axios.post(this.jwtConfig.loginEndpoint, ...args)
    //}

    //register(...args) {
    //    return axios.post(this.jwtConfig.registerEndpoint, ...args)
    //}

    //refreshToken() {
    //    return axios.post(this.jwtConfig.refreshEndpoint, {
    //        refreshToken: this.getRefreshToken()
    //    })
    //}
}
