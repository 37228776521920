/**
  const currentTenantChanged = useCallback(
    (data) => console.log("data", data),
    []
  );

  useEffect(() => {
    on(currentTenantChangedEvtName, currentTenantChanged);
    return () => {
      off(currentTenantChangedEvtName, currentTenantChanged);
    };
  }, [currentTenantChanged]);
 */

function on(eventType, listener) {
    document.addEventListener(eventType, listener)
}

function off(eventType, listener) {
    document.removeEventListener(eventType, listener)
}

function once(eventType, listener) {
    function handleEventOnce(event) {
        listener(event)
        off(eventType, handleEventOnce)
    }
    on(eventType, handleEventOnce)
}

function trigger(eventType, data) {
    const event = new CustomEvent(eventType, { detail: data })
    document.dispatchEvent(event)
}

export { on, once, off, trigger }
