const host = window.location.host

export const IDENTITY_CONFIG = {
    authority: process.env.REACT_APP_ISSUER_AUTHORITY_URL,
    // client_id: process.env.REACT_APP_IDENTITY_CLIENT_ID,
    redirect_uri: process.env.REACT_APP_REDIRECT_URL.replace('{0}', host),
    silent_redirect_uri: process.env.REACT_APP_SILENT_REDIRECT_URL.replace('{0}', host),
    post_logout_redirect_uri: process.env.REACT_APP_LOGOFF_REDIRECT_URL.replace('{0}', host),
    // audience: process.env.REACT_APP_AUDIENCE,
    response_type: `code`,
    automaticSilentRenew: false,
    loadUserInfo: false
    // ,scope: process.env.REACT_APP_SCOPE
  }
  
  export const METADATA_OIDC = {
    issuer: `${process.env.REACT_APP_ISSUER_AUTHORITY_URL}/`, 
    jwks_uri: `${process.env.REACT_APP_ISSUER_AUTHORITY_URL}/.well-known/openid-configuration`,
    authorization_endpoint: `${process.env.REACT_APP_ISSUER_AUTHORITY_URL}/connect/authorize`,
    token_endpoint: `${process.env.REACT_APP_ISSUER_AUTHORITY_URL}/connect/token`,
    userinfo_endpoint: `${process.env.REACT_APP_ISSUER_AUTHORITY_URL}/connect/userinfo`,
    end_session_endpoint: `${process.env.REACT_APP_ISSUER_AUTHORITY_URL}/connect/logout`,
    check_session_iframe: `${process.env.REACT_APP_ISSUER_AUTHORITY_URL}/connect/verify`,
    revocation_endpoint: `${process.env.REACT_APP_ISSUER_AUTHORITY_URL}/connect/revocation`,
    introspection_endpoint: `${process.env.REACT_APP_ISSUER_AUTHORITY_URL}/connect/introspect`  
  }
  