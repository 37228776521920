import '@assetsPortalFacil/scss/admin/components/admin/Message/Alert.scss'

import { Card, CardBody, CardText, CardTitle } from 'reactstrap'
import { ChevronDown, ChevronUp } from 'react-feather'

import FormAccordion from '../DynamicForm/FormAccordion'
import FormAlert from '../DynamicForm/FormAlert'
import { Fragment } from 'react'
import Proptypes from 'prop-types'
import Swal from 'sweetalert2'
import { isNullOrEmpty } from '@src/utility/Utils'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

export const AlertSuccess = (title, message) => {
    return MySwal.fire({
        title: `${title}`,
        text: `${message}`,
        icon: 'success',
        customClass: {
            confirmButton: 'btn btn-primary'
        },
        buttonsStyling: false,
        allowOutsideClick: false
    })
}

export const AlertError = (
    title,
    message,
    moreInformation = 'Sem dados para exibir',
    width = '50%'
) => {
    //const [showMoreInfo, setShowMoreInfo] = useState(false)
    title = !title ?? process.env.REACT_APP_APP_NAME
    return MySwal.fire({
        // title: `${title}`,
        // text: `${message}`,
        icon: 'error',
        customClass: {
            confirmButton: 'btn btn-primary'
        },
        showClass: {
            popup: 'animate__animated animate__fadeIn'
        },
        buttonsStyling: false,
        width: width,
        html: (
            <Fragment>
                <Card>
                    <CardBody>
                        <CardTitle tag="h4">{title}</CardTitle>
                        <CardText>{message}</CardText>
                    </CardBody>

                    <CardBody>
                        <FormAccordion
                            title="Mais informações"
                            showOpen={false}
                            visible={true}
                            className="alert-error-with-parameter"
                        >
                            <FormAlert
                                icon={''}
                                color="danger"
                                text={
                                    <ul>
                                        <p>{moreInformation}</p>
                                    </ul>
                                }
                            />
                        </FormAccordion>
                    </CardBody>
                </Card>
            </Fragment>
        ),
        allowOutsideClick: false
    })
}

export const AlertErrorCatch = (error, mensagem = '') => {
    console.log('AlertErrorCatch->error:', error)
    let status = ''
    let maisInformacoes = ''
    if (!isNullOrEmpty(mensagem)) {
        console.log('linha: 87')
        status = error?.response?.status || 900
        maisInformacoes =
            error?.message === 'Network Error'
                ? 'Favor verificar a conexão com a Internet/API.'
                : `${error?.response?.data || 'Sem dados para exibir.'}`
    } else if (error?.response?.errors) {
        console.log('linha: 94')
        status = 469
        mensagem = `Não foi possível processar sua solicitação. <br/>Clique em 'Mais informações' para detalhes.`
        maisInformacoes = `Sem mais informações`
    } else if (error?.response?.status === 405) {
        status = error?.response?.status
        mensagem = `Método não permitido`
        maisInformacoes = `HTTP Error 405 - Este status de resposta indica que o verbo HTTP utilizado não é suportado`
    } else if (error?.response?.status === 404) {
        status = error?.response?.status
        mensagem = `Método não encontrado`
        maisInformacoes = `HTTP Error 404 - Este status de resposta indica que o servidor não conseguiu encontrar o recurso solicitado.`
    } else if (error?.response?.status === 403) {
        status = error?.response?.status
        mensagem = `Acesso negado`
        maisInformacoes = `HTTP Error 403 - Este status de resposta indica que o servidor entendeu o pedido, mas se recusa a autorizá-lo. Acesso negado.`
    } else if (error?.response?.status === 401) {
        status = error?.response?.status
        mensagem = `Não autorizado`
        maisInformacoes = `HTTP Error 401 - Este status de resposta indica que a solicitação não foi aplicada porque não possui credenciais de autenticação válidas para o recurso de destino. Não autorizado.`
    } else if (error?.response) {
        console.log('linha: 109', error?.response)
        status = error?.response?.status
        mensagem = `Não foi possível processar sua solicitação. Clique em 'Mais informações' para detalhes.`

        // const jsonPretty = JSON.stringify(JSON.parse(error?.response?.data?.erros), null, 2)
        maisInformacoes = `${JSON.stringify(error?.response?.data) || 'Sem dados para exibir.'}`
        //maisInformacoes = `${jsonPretty || 'Sem dados para exibir.'}`
        if (isNullOrEmpty(maisInformacoes) || maisInformacoes === '""') {
            if (status === '404') {
                maisInformacoes = 'Não encontrado'
            } else if (status === '403') {
                maisInformacoes = 'Acesso negado'
            } else if (!isNullOrEmpty(error?.message)) {
                maisInformacoes = error?.message
            } else {
                maisInformacoes = 'Sem dados para exibir.'
            }
        }
    } else if (error?.message) {
        console.log('linha: 128')
        status = 409
        mensagem =
            error?.message === 'Network Error'
                ? 'Não foi possível conectar-se a API.'
                : error?.message
        maisInformacoes =
            error?.message === 'Network Error'
                ? 'Favor verificar a conexão com a Internet/API.'
                : error?.message
    } else {
        console.log('linha: 139')
        status = 503
        mensagem = error
    }
    return AlertError(process.env.REACT_APP_APP_NAME, `${status} - ${mensagem}`, maisInformacoes)
}

export const AlertErrorGetList = (moreInformation, width = '80%') => {
    const title = 'Ocorreu um erro ao carregar listagem'
    const message = `Não foi possível processar sua solicitação. Clique em 'Mais informações' para detalhes.`
    return AlertError(title, message, moreInformation, width)
}
export const AlertInfo = (title, message) => {
    return MySwal.fire({
        title: `${title}`,
        text: `${message}`,
        icon: 'info',
        customClass: {
            confirmButton: 'btn btn-primary'
        },
        buttonsStyling: false,
        allowOutsideClick: false,
        html: `${message}`
    })
}

export const AlertWarning = (title, message) => {
    return MySwal.fire({
        title: `${title}`,
        text: `${message}`,
        icon: 'warning',
        customClass: {
            confirmButton: 'btn btn-primary'
        },
        buttonsStyling: false,
        allowOutsideClick: false
    })
}

export const AlertFormValidationError = (
    message = `Favor corrigir os campos destacados em vermelho.`
) => {
    return MySwal.fire({
        title: 'Erro ao processar sua solicitação',
        text: message,
        icon: 'error',
        showCancelButton: false,
        confirmButtonText: 'Ok',
        confirmButtonColor: '#3085d6',
        customClass: {
            confirmButton: 'btn btn-primary'
        },
        showClass: {
            popup: 'animate__animated animate__fadeIn'
        },
        buttonsStyling: false,
        allowOutsideClick: false
    }).then(result => {
        if (result.isConfirmed) {
        } else if (result.dismiss === Swal.DismissReason.cancel) {
        }
    })
}

export const AlertConfirmDelete = (
    title = 'Apagar Registro',
    message = 'Deseja realmente apagar esse registro?'
) => {
    return MySwal.fire({
        title: `${title}`,
        text: `${message}`,
        icon: 'error',
        confirmButtonText: 'Sim, Apagar ',
        confirmButtonColor: '#3085d6',
        showCancelButton: true,
        cancelButtonText: ' Não ',
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-outline-primary ml-1'
        },
        showClass: {
            popup: 'animate__animated animate__fadeIn'
        },
        buttonsStyling: false,
        allowOutsideClick: false
    })
}

export const AlertConfirmAction = (
    title = 'Atenção',
    message = 'Deseja realmente realizar esta operação?',
    confirmButtonText = 'Sim.'
) => {
    return MySwal.fire({
        title: `${title}`,
        text: `${message}`,
        icon: 'error',
        confirmButtonText,
        confirmButtonColor: '#3085d6',
        showCancelButton: true,
        cancelButtonText: ' Não ',
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-outline-primary ml-1'
        },
        showClass: {
            popup: 'animate__animated animate__fadeIn'
        },
        buttonsStyling: false,
        allowOutsideClick: false
    })
}

export const Alert = (message, title = process.env.REACT_APP_APP_NAME, icon = 'success') => {
    return MySwal.fire({
        title: `${title}`,
        text: `${message}`,
        icon: `${icon}`,
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: `Ok`,
        confirmButtonColor: '#3085d6',
        cancelButtonText: `Cancelar`,
        customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-primary ml-1'
        },
        showClass: {
            popup: 'animate__animated animate__fadeIn'
        },
        buttonsStyling: false,
        allowOutsideClick: false
    }).then(result => {
        return result
    })
}

export const AlertReturnResponse = (
    message = 'Seu registro foi salvo com sucesso',
    icon = 'success',
    confirmButtonText = 'Voltar para listagem',
    cancelButtonText = 'Voltar para registro',
    showConfirmButton = true,
    showCancelButton = true
) => {
    const title = process.env.REACT_APP_APP_NAME
    return MySwal.fire({
        title: `${title}`,
        text: `${message}`,
        icon: `${icon}`,
        showCancelButton: showCancelButton,
        showConfirmButton: showConfirmButton,
        confirmButtonText: `${confirmButtonText}`,
        confirmButtonColor: '#3085d6',
        cancelButtonText: `${cancelButtonText}`,
        customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-primary ml-1'
        },
        showClass: {
            popup: 'animate__animated animate__fadeIn'
        },
        buttonsStyling: false,
        allowOutsideClick: false
    }).then(result => {
        return result
    })
}

export const AlertErrorWithParameter = (title, message, erros, width = '50%') => {
    return MySwal.fire({
        // title: `${title}`,
        // text: `${message}`,
        icon: 'error',
        customClass: {
            confirmButton: 'btn btn-primary'
        },
        showClass: {
            popup: 'animate__animated animate__fadeIn'
        },
        buttonsStyling: false,
        width,
        html: (
            <Fragment>
                <Card>
                    <CardBody>
                        <CardTitle tag="h4">{title}</CardTitle>
                        <CardText>{message}</CardText>
                    </CardBody>

                    <CardBody>
                        <FormAccordion
                            title="Mais informações"
                            showOpen={false}
                            visible={true}
                            className="alert-error-with-parameter"
                        >
                            <FormAlert
                                icon={''}
                                color="danger"
                                text={
                                    <ul>
                                        {erros?.map((x, i) => {
                                            if (!isNullOrEmpty(x)) {
                                                return <li key={`${i}`}>{x}</li>
                                            }
                                        })}
                                    </ul>
                                }
                            />
                        </FormAccordion>
                    </CardBody>
                </Card>
            </Fragment>
        ),
        allowOutsideClick: false
    })
}

Alert.propTypes = {
    icon: Proptypes.oneOf(['success', 'info', 'warning', 'question', 'error'])
}

Alert.defaultProps = {
    icon: 'info'
}
