/* eslint-disable no-unused-vars */

import { Ability, defineAbility } from '@casl/ability'

import AuthService from '../../services/AuthService'
import { initialAbility } from './initialAbility'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
// const userData = AuthService.restoreUserData()
// const existingAbility = userData ? userData.ability : null

//Update the rules of Ability after logged in
// export default new Ability(
//     [
//         {
//             action: 'manage',
//             subject: 'all'
//         }
//     ] /*existingAbility || initialAbility*/
// )

export default defineAbility((can, cannot) => {
    can('manage', 'all')
    //cannot('delete', 'User')
})
