import axios from 'axios'
import { formatAuthMessage } from '@src/utility/message-utils'

const urlApi = `${process.env.REACT_APP_API_URL}/api/`
const RETRY_IN_SECONDS = 10

const prefix = 'oauth.'
const hostname = window.location.hostname
const oauthClientSettingsKey = `${prefix}clientSettings:${hostname}`

class AppService {
    httpClient = null
    ConfigIsFetched = false
    OAuthConfig = null
    InitPromise = null
    Resolvers = []
    _timeOutId = null
    isLoggingEnabled = false
    _initPromise = null

    constructor() {
        if (parseInt(process.env.REACT_APP_AUTH_LOGGING_ENABLED, 10) === 1) {
            this.isLoggingEnabled = true
        }

        this.httpClient = axios.create({
            baseURL: urlApi,
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-type': 'application/json'
            }
        })

        this.OAuthConfig = null
    }

    RetryInit(resolve) {
        clearTimeout(this._timeOutId)
        if (this.isLoggingEnabled) {
            console.warn(
                formatAuthMessage(
                    `OAuthConfig was NOT Obtained, waiting to retry again in ${RETRY_IN_SECONDS} seconds......`
                )
            )
        }
        this._timeOutId = setTimeout(() => {
            if (this.isLoggingEnabled) {
                console.warn(formatAuthMessage('Trying get OAuthConfig again ...'))
            }
            this.GetAsync(resolve)
        }, RETRY_IN_SECONDS * 1000)
    }

    GetAsync(resolve) {
        const that = this
        const dmLocal = 1 // 1 - Admin 2 - Public
        return this.httpClient
            .get(`ClientSettings/OAuth/${dmLocal}`)
            .then(res => {
                if (res.data?.Data === null) {
                    that.RetryInit(resolve)
                } else {
                    const oauthConfig = res.data?.Data
                    if (this.isLoggingEnabled) {
                        console.warn(
                            formatAuthMessage(
                                'OAuthClientSettings has been loaded from remote server!'
                            ),
                            oauthConfig
                        )
                    }
                    that.OAuthConfig = oauthConfig
                    this.SaveOAuthClientConfigLocally(oauthConfig)
                    resolve(oauthConfig)
                }
            })
            .catch(() => this.RetryInit(resolve))
    }

    async InitAsync() {
        const that = this
        this._initPromise = new Promise(resolve => {
            if (that.isLoggingEnabled) {
                console.warn(formatAuthMessage('Trying to get OAuthClientSettings...'))
            }

            const localConfig = this.TryRestoreClientSettingsLocally()
            if (localConfig) {
                if (this.isLoggingEnabled) {
                    console.warn(
                        formatAuthMessage(
                            'OAuthClientSettings has been loaded from sessionStorage!'
                        ),
                        localConfig
                    )
                }
                that.OAuthConfig = localConfig
                resolve(localConfig)
            } else {
                if (this.isLoggingEnabled) {
                    console.warn(
                        formatAuthMessage(
                            'Going up to remote server to require the OAuthClientSettings...'
                        )
                    )
                }
                that.GetAsync(resolve)
            }
        })

        return this._initPromise
    }

    TryRestoreClientSettingsLocally() {
        return JSON.parse(window.sessionStorage.getItem(oauthClientSettingsKey))
    }

    SaveOAuthClientConfigLocally(oauthSettings) {
        // saving the oauthClientSettings locally
        window.sessionStorage.setItem(oauthClientSettingsKey, JSON.stringify(oauthSettings))
    }

    GetOAuthConfigAsync() {
        // console.log('this', this)
        // if (this.AuthConfig !== null) {
        //     console.log('this.AuthConfig', this.AuthConfig)
        //     return Promise.resolve(this.OAuthConfig)
        // }

        return this._initPromise ?? this.InitAsync()
    }
}

export default new AppService()
