/* eslint-disable no-unused-vars */

import { IDENTITY_CONFIG, METADATA_OIDC } from './AuthConst'
import { Log, UserManager, WebStorageStateStore } from 'oidc-client-ts'

import AppService from './AppService'
import ability from '@src/configs/acl/ability'
import { empresaSelectedChangedEventName } from '@src/constants/custom-event-names'
import { formatAuthMessage } from '@src/utility/message-utils'
import { getAsync } from '@src/providers/DataProvider'
import { trigger } from 'src/utility/events'

const AVATAR_BASE_URL = `${process.env.REACT_APP_ISSUER_AUTHORITY_URL}/connect/userimage/{0}`
const MAX_ATTEMPT_GET_USER_ROLES = 10
const MAX_ATTEMPT_GET_USER_MENU = 10

const isAuthLoggingEnabled = parseInt(process.env.REACT_APP_AUTH_LOGGING_ENABLED, 10) === 1
const sessionStoragePrefixKey = 'unific.'
const sessionStorageKey = `${sessionStoragePrefixKey}user:${process.env.REACT_APP_ISSUER_AUTHORITY_URL}:{0}`

const additionalPolicyPrefix = 'policy.'
const additionalMenuPrefix = 'menu.'

const empresaPrefix = 'empresa.'
const hostname = window.location.hostname
const empresaStorageKey = `${empresaPrefix}clientSettings:${hostname}`

function defineRulesFor(user) {
    ability.can(['manage'], 'all')
    if (isAuthLoggingEnabled) {
        console.warn(formatAuthMessage('Ability is being defined'), { ability, user })
    }
}
class AuthService {
    // UserManager = null
    // isDebugMode = false
    // appService = null
    _initAsyncCallback = null
    _user = null
    UserManager = null

    initImplm(oauthConfig) {
        const that = this
        if (that.isLoggingEnabled) {
            console.warn(formatAuthMessage('UserManager is being instantiated...'))
        }
        const fromServer = {
            client_id: oauthConfig.ClientId,
            audience: oauthConfig.Audience,
            scope: oauthConfig.Scopes.join(' ')
        }

        that.UserManager = new UserManager({
            ...IDENTITY_CONFIG,
            userStore: new WebStorageStateStore({
                store: window.sessionStorage,
                prefix: sessionStoragePrefixKey
            }),
            metadata: {
                ...METADATA_OIDC
            },
            ...fromServer
        })

        that.UserManager.events.addUserLoaded(user => {
            if (that.isLoggingEnabled) {
                console.warn(formatAuthMessage('UserManager event UserLoaded is raised'), user)
            }

            //if the user was not settled yet.
            if (!that._user) {
                const params = AuthService.parseSearchUrlParameters(window.location.url)

                if (that.isLoggingEnabled) {
                    console.warn(
                        formatAuthMessage(
                            'Setting user instance from UserManager event UserLoaded'
                        ),
                        user
                    )
                }
                const key = this.getSessionStorageKey()
                const restoreLocal = that.restoreLocal(key)
                that.saveLocal(key, {
                    ...restoreLocal,
                    extraParams: restoreLocal?.extraParams || params
                })

                that._user = { ...user, extraParams: params }
                defineRulesFor(that._user)
            }
        })

        that.UserManager.events.addSilentRenewError(e => {
            if (that.isLoggingEnabled) {
                console.warn(
                    formatAuthMessage('UserManager event SilentRenewError is raised'),
                    e.message
                )
            }
        })

        that.UserManager.events.addAccessTokenExpiring(() => {
            if (that.isLoggingEnabled) {
                console.warn(formatAuthMessage('UserManager event AccessTokenExpiring is raised'))
            }
            that.signinSilent()
        })

        that.UserManager.events.addAccessTokenExpired(() => {
            if (that.isLoggingEnabled) {
                console.warn(formatAuthMessage('UserManager event AccessTokenExpired is raised'))
            }
            that.logout()
        })

        if (that.isLoggingEnabled) {
            console.warn(formatAuthMessage('UserManager has been initialized...'))
        }
    }

    initAsync() {
        const that = this
        return new Promise(resolve => {
            AppService.GetOAuthConfigAsync().then(oauthConfig => {
                that.initImplm(oauthConfig)
                resolve(that)
            })
        })
    }

    constructor() {
        const that = this
        this.UserManager = null
        // Logger
        if (isAuthLoggingEnabled) {
            Log.logger = console
            Log.level = Log.DEBUG
            this.isLoggingEnabled = true
        }

        this._initAsyncCallback = this.initAsync()
        this._initAsyncCallback
            .then(e => {
                if (that.isLoggingEnabled) {
                    console.warn(formatAuthMessage('AuthService initialized successfully.'), e)
                }
            })
            .catch(e => {
                if (that.isLoggingEnabled) {
                    console.warn(formatAuthMessage('Fail to initialized AuthService.'), e)
                }
            })
    }

    signinRedirectCallback = () => {
        const that = this
        window.location.hash = decodeURIComponent(window.location.hash)

        return this.UserManager.signinRedirectCallback().then(() => {
            if (that.isLoggingEnabled) {
                console.warn(
                    formatAuthMessage(
                        'signinRedirectCallback -> The user modified is being returned'
                    )
                )
            }

            return this._user
        })
    }

    saveLocal(key, value) {
        window.sessionStorage.setItem(
            key,
            typeof value === 'string' ? value : JSON.stringify(value)
        )
    }

    restoreLocal(key) {
        const jsonPayload = window.sessionStorage.getItem(key)
        const value = JSON.parse(jsonPayload)
        return value
    }

    saveMenu(value) {
        const key = this.getMenuSessionKey()
        this.saveLocal(key, value)
    }

    restoreMenu() {
        const key = this.getMenuSessionKey()
        const menu = this.restoreLocal(key)
        return menu
    }

    savePolicies(value) {
        const key = this.getPolicySessionKey()
        this.saveLocal(key, value)
    }

    restorePolicies() {
        const key = this.getPolicySessionKey()
        const policies = this.restoreLocal(key)
        return policies
    }

    getClientId() {
        const clientId = AppService.OAuthConfig?.ClientId
        if (!clientId) throw new Error('OAUTH_CLIENTID_IS_NOT_AVAILABLE_YET')
        return clientId
    }

    getPolicySessionKey() {
        const clientId = this.getClientId()
        const key = `${additionalPolicyPrefix}${sessionStorageKey.replace('{0}', clientId)}`
        return key
    }

    getMenuSessionKey() {
        const clientId = this.getClientId()
        const key = `${additionalMenuPrefix}${sessionStorageKey.replace('{0}', clientId)}`
        return key
    }

    getSessionStorageKey = () => {
        const clientId = this.getClientId()
        return sessionStorageKey.replace('{0}', clientId)
    }

    getAdditionalParams = () => {
        if (this.isUserAuthenticated()) {
            const key = this.getSessionStorageKey()
            return this.restoreLocal(key)
        }
        return null
    }

    static parseSearchUrlParameters(url) {
        // get query string from url (optional) or window
        // const queryString = url ? url.split('?')[1] : window.location.search.slice(1)

        // we'll store the parameters here
        const values = {},
            urlParams = new URLSearchParams(window.location.search),
            keys = urlParams.keys(),
            ignoredKeys = [
                'error',
                'error_description',
                'error_uri',
                'code',
                'state',
                'id_token',
                'session_state',
                'access_token',
                'token_type',
                'scope',
                'profile',
                'expires_in'
            ] // keys of oidc

        for (const key of keys) {
            const value = urlParams.getAll(key)
            if (ignoredKeys.indexOf(key) === -1 && Array.isArray(value)) {
                values[key] = value.length > 1 ? value : value[0]
            }
        }
        return values
    }

    getUserDataAsync = (refresh = false) => {
        if (this.isLoggingEnabled) {
            console.warn(formatAuthMessage('Trying to get user data...'))
        }

        if (!this.isUserAuthenticated()) {
            if (this.isLoggingEnabled) {
                console.warn(
                    formatAuthMessage(
                        'Impossible to retrieve the user data due user is not authenticated...'
                    )
                )
            }
            return Promise.reject(null)
        }

        const that = this

        return new Promise(async (resolve, reject) => {
            const token = that.getToken()
            if (!token) {
                if (that.isLoggingEnabled) {
                    console.warn(formatAuthMessage('Token is null rejecting with null...'))
                }
                return reject(null)
            }

            const { name, fullname, email, username, sub } = that.parseJwt(token)

            const avatarUrl = AVATAR_BASE_URL.replace('{0}', username)

            that.getUserRolesAsync(refresh)
                .then(policy => {
                    const userData = {
                        Id: sub,
                        Name: name,
                        FullName: fullname,
                        UserName: username,
                        Avatar: avatarUrl,
                        Status: policy?.status,
                        IsAccessGranted: policy?.status === 'Aprovado',
                        policy,
                        extraParams: that.user.extraParams,
                        Email: email,
                        ability: [
                            {
                                action: 'manage',
                                subject: 'all'
                            }
                        ]
                    }

                    if (policy?.status === 'Aprovado') {
                        that.getUserMenuAsync(refresh)
                            .then(menu => {
                                resolve({
                                    ...userData,
                                    menu
                                })
                            })
                            .catch(e => {
                                if (that.isLoggingEnabled) {
                                    console.warn(
                                        formatAuthMessage(
                                            'Fail to get UserMenu from server, reason: '
                                        ),
                                        e
                                    )
                                }
                                reject(e)
                            })
                    } else {
                        if (that.isLoggingEnabled) {
                            console.warn(
                                formatAuthMessage('User is not approved, rejecting with null.'),
                                e
                            )
                        }
                        resolve(userData)
                    }
                })
                .catch(e => {
                    if (that.isLoggingEnabled) {
                        console.warn(
                            formatAuthMessage('Fail to get UserRoles from server, reason: '),
                            e
                        )
                    }
                    reject(e)
                })
        })
    }

    fetchRecursivelyAsync(
        endpoint,
        maxAttempt,
        type,
        storageKey,
        fallback,
        resolve,
        reject,
        attempt
    ) {
        const that = this

        if (attempt <= maxAttempt) {
            if (this.isLoggingEnabled) {
                console.warn(formatAuthMessage(`Trying to get ${type} attempt ${attempt}`))
            }
            getAsync(endpoint)
                .then(resp => {
                    const data = resp.data
                    if (that.isLoggingEnabled) {
                        console.warn(formatAuthMessage(`${type} obtained successfully`), data)
                    }
                    that.saveLocal(storageKey, data)
                    resolve(data)
                })
                .catch(_ => {
                    if (that.isLoggingEnabled) {
                        console.warn(
                            formatAuthMessage(`Failed to obtain the ${type}, trying again.`)
                        )
                    }

                    that.fetchRecursivelyAsync(
                        endpoint,
                        maxAttempt,
                        type,
                        storageKey,
                        fallback,
                        resolve,
                        reject,
                        attempt + 1
                    )
                })
        } else {
            if (this.isLoggingEnabled) {
                console.warn(
                    formatAuthMessage(`Failed to obtain the ${type}, attempt limit reached.`)
                )
            }
            reject(fallback)
        }
    }

    getUserMenuAsync = (refresh = false) => {
        const menuSessionKey = this.getMenuSessionKey()
        const userMenuLocal = this.restoreMenu()
        const that = this

        if (!!userMenuLocal && !refresh) {
            if (this.isLoggingEnabled) {
                console.warn(formatAuthMessage('Loaded UserMenu from local...'))
            }
            return Promise.resolve(userMenuLocal)
        }

        if (this.isLoggingEnabled) {
            console.warn(formatAuthMessage('Trying to get UserMenu from server...'))
        }

        return new Promise((resolve, reject) => {
            that.fetchRecursivelyAsync(
                'Seguranca/GetMenu',
                MAX_ATTEMPT_GET_USER_MENU,
                'User Menu',
                menuSessionKey,
                null,
                resolve,
                reject,
                1
            )
        })
    }

    getUserRolesAsync = (refresh = false) => {
        const policySessionKey = this.getPolicySessionKey()
        const userPolicyLocal = this.restorePolicies()
        const that = this

        if (!!userPolicyLocal && !refresh) {
            if (this.isLoggingEnabled) {
                console.warn(formatAuthMessage('Loaded userRoles from local...'))
            }
            return Promise.resolve(userPolicyLocal)
        }

        if (this.isLoggingEnabled) {
            console.warn(formatAuthMessage('Trying to get userRoles from server...'))
        }

        return new Promise((resolve, reject) => {
            that.fetchRecursivelyAsync(
                'Seguranca/GetUserRoles',
                MAX_ATTEMPT_GET_USER_ROLES,
                'User Roles',
                policySessionKey,
                null,
                resolve,
                reject,
                1
            )
        })
    }

    parseJwt = token => {
        const base64Url = token.split('.')[1]
        const base64 = base64Url.replace('-', '+').replace('_', '/')
        return JSON.parse(window.atob(base64))
    }

    signinRedirect = () => {
        const t = new URLSearchParams(window.location.search)
        const redirectTo = t.get('redirectTo') || ''
        this.UserManager.signinRedirect({
            extraQueryParams: {
                //your params go here
                cdCliente: 123,
                return: redirectTo
            }
        })
    }

    restoreUserData = () => {
        if (this.isLoggingEnabled) {
            console.warn(formatAuthMessage(`Trying to restore the user data.`))
        }

        if (!this.isUserAuthenticated()) {
            if (this.isLoggingEnabled) {
                console.warn(
                    formatAuthMessage(
                        `Impossible to restore the user data, due user is not authenticated.`
                    )
                )
            }
            return null
        }
        const policy = this.restorePolicies()
        if (!policy) throw new Error('ERR_POLICY_IS_NOT_DEFINED')

        return {
            ...(this._user || {}),
            Policies: policy,
            policy: policy.roles,
            status: policy.status,
            ...{
                role: 'admin',
                ability: [
                    {
                        action: 'manage',
                        subject: 'all'
                    }
                ]
            }
        }
    }

    onLoaded = () => {
        const that = this
        return new Promise((resolve, reject) => {
            that._initAsyncCallback
                .then(() => {
                    if (!that._user) {
                        that.getUserAsync().then(user => {
                            that._user = user
                            resolve(that)
                        })
                    }
                    resolve(that)
                })
                .catch(reject)
        })
    }

    get user() {
        return this._user
    }

    getUserAsync = () => {
        return this.UserManager.getUser()
    }

    getToken = () => {
        if (this.user === null) return null
        return this.user.access_token
    }

    getRefreshToken = () => {
        if (this.user === null) return null
        return this.user.refresh_token
    }

    isUserAuthenticated = () => {
        return !!this.user
    }

    isAuthorized = () => {
        if (this.isUserAuthenticated()) {
            const policies = this.restorePolicies()
            return policies?.status === 'Aprovado'
        }
        return false
    }

    signinSilent = () => {
        return this.UserManager.signinSilent()
            .then(user => {
                console.warn(`signinSilent->user: ${JSON.stringify(user)}`)
            })
            .catch(err => {
                console.log(err)
            })
    }

    signinSilentCallback = () => {
        this.UserManager.signinSilentCallback()
    }

    createSigninRequest = () => {
        return this.UserManager.createSigninRequest()
    }

    logout = () => {
        this.UserManager.signoutRedirect({
            id_token_hint: localStorage.getItem('id_token')
        })

        this.clearUserData()
        window.location.replace('/login')
    }

    clearUserData() {
        this._user = null
        this.UserManager?.clearStaleState()
        localStorage.clear()
        sessionStorage.clear()
    }

    signoutRedirectCallback = () => {
        // logger.info('window.location: ', window.location)
        this.UserManager.signoutRedirectCallback().then(() => {
            this.clearUserData()
            window.location.replace('/login')
        })
    }

    isUserManagerInitialized = () => this.UserManager !== null

    isContribuinteOrProcurador() {
        const policies = this.restorePolicies()
        return policies?.isContribuinte === true || policies?.isProcurador === true
    }

    mustSelectEmpresa() {
        const isContruibuinteOrProcurador = this.isContribuinteOrProcurador()
        return isContruibuinteOrProcurador
    }

    isContribuinte() {
        const policies = this.restorePolicies()
        return policies?.isContribuinte === true
    }

    isProcurador() {
        const policies = this.restorePolicies()
        return policies?.isProcurador === true
    }

    isFiscal() {
        const policies = this.restorePolicies()
        return policies.isFiscal
    }

    mustDefineRequiredProperties() {
        const policies = this.restorePolicies() || {}
        return policies.isFiscal && !policies.isFiscalRequiredPropertiesDefined
    }

    setIsFiscalRequiredPropertiesDefined(value) {
        const policies = this.restorePolicies()
        this.savePolicies({ ...policies, isFiscalRequiredPropertiesDefined: value })
    }

    getCurrentEmpresa() {
        const empresaId = this.restoreLocal(empresaStorageKey)
        const policies = this.restorePolicies()
        if (!empresaId || !policies || !(policies?.empresasAssociada?.length > 0)) return null
        const currentEmpresa = policies.empresasAssociada.find(
            x => x.Id === parseInt(empresaId, 10)
        )
        if (this.isLoggingEnabled) {
            console.warn(formatAuthMessage('Restoring the selected empresa'), currentEmpresa)
        }

        return currentEmpresa
    }

    getCurrentEmpresaId() {
        const empresaId = this.restoreLocal(empresaStorageKey)
        if (this.isLoggingEnabled) {
            console.warn(formatAuthMessage('Restoring the selected empresa Id'), empresaId)
        }

        return (empresaId && parseInt(empresaId, 10)) || empresaId
    }

    setCurrentEmpresaId(empresaId) {
        this.saveLocal(empresaStorageKey, `${empresaId}`)
        const currentEmpresa = this.getCurrentEmpresa()
        if (this.isLoggingEnabled) {
            console.warn(formatAuthMessage('Saveing the selected empresa'), {
                empresaId,
                currentEmpresa
            })
        }
        trigger(empresaSelectedChangedEventName, { formData: currentEmpresa })
    }

    canSwitchEmpresa() {
        const policies = this.restorePolicies()
        return policies?.empresasAssociada?.length > 1
    }
}

export default new AuthService()
