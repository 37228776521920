import { AlertError } from '@componentsAdmin/Message/Alert'
import axios from 'axios'
import jwtService from '../@core/auth/jwt/jwtService'

const baseUrlApi = `${process.env.REACT_APP_API_URL}/api/`
const _jwtService = new jwtService(baseUrlApi)
export const http = _jwtService.getHttpClient()

const paginateArray = (array, perPage, page) => array.slice((page - 1) * perPage, page * perPage)

//#region getListDispatch()
export const getListDispatch = (controller, params) => {
    const { query = '', perPage = 10, page = 1, fieldsToQuery = [] } = params
    //console.warn(`DataProvider->getListDispatch(${controller})->params: ${JSON.stringify(params)}`)
    return dispatch => {
        _jwtService
            .getHttpClient()
            .get(controller, { params })
            .then(response => {
                //console.warn(`DataProvider->getListDispatch(${controller})->response: ${JSON.stringify(response.data)}`)
                const sanitizedQuery = query?.trim() || ''
                const filteredData =
                    fieldsToQuery?.length > 0 && sanitizedQuery !== ''
                        ? response.data.filter(function (item) {
                              return item[fieldsToQuery[0]]
                                  ?.toLowerCase()
                                  ?.includes(sanitizedQuery.toLowerCase())
                          })
                        : response.data
                //console.warn(`DataProvider->getListDispatch(${controller})->filteredData: ${JSON.stringify(filteredData)}`)

                dispatch({
                    type: 'GET_LIST',
                    allData: response.data,
                    data: paginateArray(filteredData, perPage, page),
                    total: filteredData.length,
                    totalPages: response.data?.length,
                    isLoading: false,
                    params
                })
            })
            .catch(error => {
                console.error(`DataProvider->getListDispatch: ${error}`)
                dispatch({
                    type: 'GET_LIST',
                    allData: [],
                    data: [],
                    total: 0,
                    totalPages: 0,
                    isLoading: false,
                    errorMessage: error,
                    params
                })
            })
    }
}
//#endregion

//#region getAsync

export const getAsync = (controller, id, axiosConfig) => {
    const param = id === null || id === undefined ? `${controller}` : `${controller}/${id}`
    // console.log('getAsync: ', { controller, id, param })
    return _jwtService.getHttpClient().get(param, axiosConfig)
}

//#endregion

//#region advancedSearch

export const getAdvancedSearch = (controller, data) => {
    return _jwtService.getHttpClient().post(`${controller}`, data)
}
//#endregion

//#region getListAsync()
export const getListAsync = (controller, id) => {
    // console.Error(`This is obsolet. Please use getAsync`)
    const param = id === null || id === undefined ? `${controller}` : `${controller}/${id}`
    return _jwtService.getHttpClient().get(param)
}
//#endregion

//#region getById()
export const getById = (controller, id) => {
    // console.Error(`This is obsolet. Please use getAsync`)
    const param = id === null || id === undefined ? `${controller}` : `${controller}/${id}`
    return _jwtService.getHttpClient().get(param)
}
//#endregion

//#region getDispatchById()
export const getDispatchById = (controller, id) => {
    return dispatch => {
        _jwtService
            .getHttpClient()
            .get(`${controller}/${id}`)
            .then(response => {
                //console.warn(`DataProvider->getDispatchById: ${JSON.stringify(response)}`)
                dispatch({
                    type: 'GET_BY_ID',
                    selectedRegister: response.data,
                    data: response.data,
                    bcActive: 'Editar',
                    isLoading: false
                })
            })
            .catch(error => console.error(`DataProvider->getDispatchById: ${error}`))
    }
}
//#endregion

//#region postData()
export const postData = (controller, data, axiosConfig) => {
    //console.warn(`DataProvider->postData: ${controller} - data: ${JSON.stringify(data)}`)
    return _jwtService.getHttpClient().post(`${controller}`, data, axiosConfig)
}
//#endregion

//#region putData()
export const putData = (controller, id, data, axiosConfig) => {
    // console.log(`putData: ${controller}/${id}\n`, { data })
    const paramForPut = id === null || id === undefined ? `${controller}` : `${controller}/${id}`
    return _jwtService.getHttpClient().put(paramForPut, data, axiosConfig)
}
//#endregion

//#region deleteData()
export const deleteData = (controller, id) => {
    return _jwtService.getHttpClient().delete(`${controller}/${id}`)
}
//#endregion

//#region getExternalUrl
export const getExternalUrl = url => {
    return axios.get(`${url}`)
}
//#endregion
