// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

// ** UseJWT import to get config
//import useJwt from '@src/auth/jwt/useJwt'
//const config = useJwt.jwtConfig

export const adminReducer = createSlice({
    name: 'adminReducer',
    initialState: {
        data: [],
        total: 1,
        params: {},
        allData: [],
        isLoading: true,
        selectedRegister: []
    },
    reducers: {
        GET_LIST: (state, action) => {
            const retorno = {
                ...state,
                allData: action.allData,
                data: action.data,
                total: action.totalPages,
                isLoading: action.isLoading,
                messageError: action.errorMessage,
                params: action.params
            }
            console.warn(`adminReducer(${action.type})->retorno: ${JSON.stringify(retorno)}`)
            return retorno
        },
        LIST_ISLOADING: (state, action) => {
            const retorno = {
                isLoading: action.isLoading
            }
            console.warn(`adminReducer(${action.type})->retorno: ${JSON.stringify(retorno)}`)
            return retorno
        }
    }
})

export const { GET_LIST } = adminReducer.actions

export default adminReducer.reducer
