import { Alert, Col, FormGroup } from 'reactstrap'
import Proptypes from 'prop-types'
import * as Icons from 'react-feather'
import { uniqueId } from "lodash"


export default function FormAlert(
    {
        color = 'primary'
        , icon = <Icons.Check size={18} />
        , text
        , colSize = 12
        , visible = true
        , fade = true
        , ...rest
    }) {

    //#region Variables
    const fieldName = uniqueId()
    //#endregion

    //#region return
    return (
        <Col md={colSize} sm='12' key={fieldName} className={(visible) ? '' : 'hidden'}>
            <FormGroup key={fieldName} {...rest}>
                <Alert color={color} fade={fade}>
                    {/*
                     <div className='alert-body' dangerouslySetInnerHTML={{ __html: text }}> </div>
                     */ }
                    <div className='alert-body'>
                        {icon}
                        <span className='ml-1'>
                            {text}
                        </span>
                    </div>
                </Alert>
            </FormGroup >
        </Col>
    )
    //#endregion 
}

FormAlert.propTypes = {
    color: Proptypes.oneOf(['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'dark'])
}

FormAlert.defaultProps = {
    color: 'primary'
}